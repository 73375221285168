import React, { useState, createContext } from "react";

const defaultValues = {
  substrates: {
    acrylic: {
      bundle: [],
      totalPrice: 0,
    },
    plywood: {
      bundle: [],
      totalPrice: 0,
    },
    lhdf: {
      bundle: [],
      totalPrice: 0,
    },
  },
  acrylicfalsebeads: {
    crystal: {
      bundle: [],
      totalPrice: 0,
    },
    cylinders: {
      bundle: [],
      totalPrice: 0,
    },
    octahedron: {
      bundle: [],
      totalPrice: 0,
    },
  },
  frame: {
    bundle: [],
    totalPrice: 0,
  },
  screens: {
    winter: {
      bundle: [],
      totalPrice: 0,
    },
    amsterdam: {
      bundle: [],
      totalPrice: 0,
    },
  },
  stand: {
    cake: {
      bundle: [],
      totalPrice: 0,
    },
    rubikcube: {
      bundle: [],
      totalPrice: 0,
    },
    macarons: {
      bundle: [],
      totalPrice: 0,
    },
  },
  toppers: {
    bundle: [],
    totalPrice: 0,
  },
  spatulas: {
    bundle: [],
    totalPrice: 0,
  },
  tag: {
    bundle: [],
    totalPrice: 0,
  },
  sticks: {
    bundle: [],
    totalPrice: 0,
  },
  shippingbox: {
    bundle: [],
    totalPrice: 0,
  },
  totalPrice: 0,
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [store, setStore] = useState(defaultValues);

  return (
    <StoreContext.Provider value={{ store, setStore }}>
      {children}
    </StoreContext.Provider>
  );
};
